export default {
    loadPerformDashModule (path, agentCode, opt = { itemsPerPage: 'all' }) {
		const [module, ...moduleExtraPath] = path.split('/')
		const metric = moduleExtraPath.length ? moduleExtraPath.join('/') : undefined
		const url = metric
			? `/api/private/stats/perf_dash/${module}/${agentCode}/${metric}`
			: `/api/private/stats/perf_dash/${module}/${agentCode}`
		const { cancelRequestModule = module, ...params } = opt
		const cancelRequestTitle = `PerformDash_${cancelRequestModule}`
		this.cancelQRequest(cancelRequestTitle)
        return this.getRequest(url, params, cancelRequestTitle)
            .then((json) => json)
			.catch((err) => {
				const msg = err.status === 404
					? 'Page not found.'
					: err.message
                return {
                    error: true,
                    text: msg,
                    visible: true,
                }
            })
    },

    updateIncomeGoal (value, agentCode) {
        const params = {
            ConfigName: 'MonthlyIncomeGoal',
            ConfigValue: value,
            DataType: 'number',
        }
        return this.postRequest(`api/private/stats/perf_dash/desire_income_goal/${agentCode}`, params).then(
            function(json) {
				return json
            },
            function(error) {
				return {
					error: true,
					cause: error,
				}
            })
    },

	createActivateNote (agentCode, note) {
        return this.postRequest(`/api/private/stats/perf_dash/activate_note_taker/${agentCode}`, note)
            .then((json) => json)
			.catch((err) => {
				const msg = err.status === 404 ? 'Page not found.' : err.message || err.msg
                return {
                    error: err,
                    message: msg,
                }
            })
	},

	updateActivateNote (note) {
        return this.putRequest(`/api/private/stats/perf_dash/activate_note_taker/${note.ID}`, note)
            .then((json) => json)
			.catch((err) => {
				const msg = err.status === 404 ? 'Page not found.' : err.message || err.msg
                return {
                    error: err,
                    message: msg,
                }
            })
	},

	createMonthlyExternalLeadSource (agentCode, form) {
        return this.postRequest(`/api/private/stats/perf_dash/activate_external_lead/${agentCode}`, form)
            .then((json) => json)
			.catch((err) => {
				const msg = err.status === 404 ? 'Page not found.' : err.message || err.msg
                return {
                    error: err,
                    message: msg,
                }
            })
	},

	updateMonthlyExternalLeadSource (form) {
        return this.putRequest(`/api/private/stats/perf_dash/activate_external_lead/${form.ID}`, form)
            .then((json) => json)
			.catch((err) => {
				const msg = err.status === 404 ? 'Page not found.' : err.message || err.msg
                return {
                    error: err,
                    message: msg,
                }
            })
	},

	deleteMonthlyExternalLeadSource (form) {
        return this.deleteRequest(`/api/private/stats/perf_dash/activate_external_lead/${form.ID}`)
            .then((json) => json)
			.catch((err) => {
				const msg = err.status === 404 ? 'Page not found.' : err.message
                return {
                    error: err,
                    message: msg,
                }
            })
	},

	createActivateActivityTracker (agentCode, params) {
        return this.postRequest(`/api/private/stats/perf_dash/activate_activity_tracker/${agentCode}`, params)
            .then((json) => json)
			.catch((err) => {
				const msg = err.status === 404 ? 'Page not found.' : err.message || err.msg
                return {
                    error: err,
                    message: msg,
                }
            })
	},

	updateActivateActivityTracker (agentCode, ID, params) {
        return this.putRequest(`/api/private/stats/perf_dash/activate_activity_tracker/${agentCode}/${ID}`, params)
            .then((json) => json)
			.catch((err) => {
				const msg = err.status === 404 ? 'Page not found.' : err.message || err.msg
                return {
                    error: err,
                    message: msg,
                }
            })
	},

	deleteActivateActivityTracker (agentCode, ID) {
        return this.deleteRequest(`/api/private/stats/perf_dash/activate_activity_tracker/${agentCode}/${ID}`)
            .then((json) => json)
			.catch((err) => {
				const msg = err.status === 404 ? 'Page not found.' : err.message
                return {
                    error: err,
                    message: msg,
                }
            })
	},
}
